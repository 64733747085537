import { PasswordReset } from "@/models/entities/password-reset.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "resetpassword";

// Reset password from login when the user is unlogged
class ResetPasswordService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  SendResetPswLink(userEmail: string) {
    return this.getAll<void>(`${controller}/${userEmail}`);
  }

  ResetPassword(passwordReset: PasswordReset) {
    return this.post<PasswordReset, void>(
      `${controller}/passwordreset`,
      passwordReset
    );
  }

  CheckResetPswToken(token: string) {
    return this.getAll<boolean>(`${controller}/checktoken?tokenPass=${token}`);
  }
}

export const resetPasswordService = new ResetPasswordService();
